import React from 'react';
import Quantify from '../../assets/quantify_cs.svg';
import Ownership from '../../assets/ownership_cs.svg';
import DataControl from '../../assets/data_control.svg';
import Strategy2019 from '../../assets/strategies_2019.svg';
import RoiCs from '../../assets/roi_cs.svg';
import Twitter from '../../assets/vector/Vector-1.svg';
import Linkedln from '../../assets/vector/Vector-2.svg';
import Youtube from '../../assets/vector/Vector-3.svg';
import GrowBusiness from '../../assets/GrowBusines.png';
import Phone from '../../assets/icon_phone.svg';
import Email from '../../assets/icon_mail.svg';
import { Link, useNavigate } from 'react-router-dom';

const Footer = ({ handleNavigation }) => {

  const handleNav = (id, path) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      // Navigate to the path if the element is not found
      navigate(path);
    }
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
    handleNavigation(id, path); // Pass ID and path to Navbar component
  };


  const navigate = useNavigate();

  const handlePrivacy = (e) => {
    e.preventDefault();
    navigate('/privacypolicy');
  };


  return (
    <>
      <div className='py-[3%] md:px-[10%] px-[2%]  '>
        <div>
          <ul className='w-full justify-between flex'>
          <div>
            <li onClick={() => { handleNav('GrowBusiness', '/grow-your-business-by-growing-customer-numbers') }} className='mb-[10px] flex gap-5 cursor-pointer'>
              <img className='rounded-lg' height='87' width='87' src={GrowBusiness} />
              <div>
                <span className='font-normal font-bold text-[16px] leading-[22px] text-blueBlack pt-[5px]'>Grow Your Business by Growing Customer Numbers</span>
                <div className='flex gap-2 text-textGrayColor uppercase text-[12px]'>
                  <div>By Prashant</div>
                </div>
              </div>
            </li>
            <li onClick={() => { handleNav('Quantify', '/how-to-quantify-customer-success') }} className='mb-[10px] flex gap-5 cursor-pointer '>
              <img className='rounded-lg' height='87' width='87' src={Quantify} />
              <div>
                <span className='font-normal font-bold text-[16px] leading-[22px] text-blueBlack pt-[5px]'>How to Quantify Customer Success</span>
                <div className='flex gap-2 text-textGrayColor uppercase text-[12px]'>
                  <div>By Prashant</div>
                </div>
              </div>
            </li>
            <li onClick={() => { handleNav('Ownership', '/who-should-own-customer-success') }} className='mb-[10px] flex gap-5 cursor-pointer '>
              <img className='rounded-lg' height='87' width='87' src={Ownership} />
              <div>
                <span className='font-normal font-bold text-[16px] leading-[22px] text-blueBlack pt-[5px]'>Who should own Customer Success</span>
                <div className='flex gap-2 text-textGrayColor uppercase text-[12px]'>
                  <div>By Balaji</div>
                </div>
              </div>
            </li>
            </div>
            <div>
            <li onClick={() => { handleNav('DataControl', '/controlling-your-customer-success-data-in-the-cloud') }} className='mb-[10px] flex gap-5 cursor-pointer'>
              <img className='rounded-lg' height='87' width='87' src={DataControl} />
              <div>
                <span className='font-normal font-bold text-[16px] leading-[22px] text-blueBlack pt-[5px]'>Controlling Your Customer Success Data in the Cloud</span>
                <div className='flex gap-2 text-textGrayColor uppercase text-[12px]'>
                  <div>By Vivek</div>
                </div>
              </div>
            </li>
            <li onClick={() => { handleNav('RoiCs', '/getting-full-roi-from-your-customer-success-investment-3') }} className='mb-[10px] flex gap-5 cursor-pointer'>
              <img className='rounded-2xl' height='87' width='87' src={RoiCs} />
              <div>
                <span className='font-normal font-bold text-[16px] leading-[22px] text-blueBlack pt-[5px]'>Getting Full ROI from your Customer Success Investment</span>
                <div className='flex gap-2 text-textGrayColor uppercase text-[12px]'>
                  <div>By Vivek</div>
                </div>
              </div>
            </li>
            <li onClick={() => { handleNav('Strategy2019', '/4-effective-customer-success-strategies-for-2019') }} className='mb-[10px] flex gap-5 cursor-pointer'>
              <img className='rounded-2xl' height='87' width='87' src={Strategy2019} />
              <div>
                <span className='font-normal font-bold text-[16px] leading-[22px] text-blueBlack pt-[5px]'>4 Effective Customer Success Strategies for 2019</span>
                <div className='flex gap-2 text-textGrayColor uppercase text-[12px]'>
                  <div>By Balaji</div>
                </div>
              </div>
            </li>
            </div>
          </ul>
        </div>
      </div>
      <div id='footer' className='flex sm:items-center sm:justify-center px-[2%] md:px-[10%]'>
        <div className='justify-between flex  w-full'>
          <div>
            <h2 className='text-textGrayColor leading-[40px]'>Product</h2>
            <div>
              <ul className='text-dimGray text-[14px] leading-[30px] cursor-pointer'>
                <li onClick={() => handleNav('Data', '/data-integration')}>Data Integration</li>
                <li onClick={() => handleNav('UIBuilder', '/ui-builder')}>UI Builder</li>
                <li onClick={() => handleNav('Alerts', '/triggered-alerts')}>Triggered Alerts</li>
                <li onClick={() => handleNav('External', '/external-process-automation')}>External Process Automation</li>
                <li onClick={() => handleNav('Playbook', '/playbook')}>Playbooks</li>
              </ul>
            </div>
          </div>
          <div>
            <h2 className='text-textGrayColor leading-[40px]'>Solutions</h2>
            <div>
              <ul className='text-dimGray text-[14px] leading-[30px] cursor-pointer'>
                <li onClick={() => handleNav('Customer360', '/customer360')}>Customer 360</li>
                <li onClick={() => handleNav('CustomerOnboarding', '/customer-onboarding')}>Customer Onboarding</li>
                <li onClick={() => handleNav('Renewal', '/renewal-management')}>Renewal Management</li>
                <li onClick={() => handleNav('Tech', '/tech-touch-customer-success')}>Tech Touch Customer Success</li>
                <li onClick={() => handleNav('Success', '/success-planning')}>Success Planning</li>
              </ul>
            </div>
          </div>
          <div>
            <h2 className='text-textGrayColor leading-[40px]'>Personas</h2>
            <div>
              <ul className='text-dimGray text-[14px] leading-[30px] cursor-pointer'>
                <li onClick={() => handleNav('CustomerSuccess', '/customer-success-manager')}>Customer Success Manager</li>
                <li onClick={() => handleNav('Executive', '/executives-cxo')}>Executives – CXOs & VPs</li>
                <li onClick={() => handleNav('CustomerOperation', '/customer-success-operations')}>Customer Success Operations</li>
              </ul>
            </div>
          </div>
          <div>
            <h2 className='text-textGrayColor leading-[40px]'>Contact Info</h2>
            <div>
              <ul className='text-dimGray text-[14px] leading-[30px]'>
                <li className='cursor-pointer' onClick={() => handleNav('About', '/about')}>About Us</li>
                <li className='cursor-pointer' onClick={() => handleNav('Career', '/career')}>Career</li>
                <li>1525 McCarthy Blvd, Milpitas, CA 95035</li>
                <li className='flex gap-2'><img src={Phone} /> +1-415-938-6456</li>
                <li className='flex gap-2'><img src={Email} /> sales@success4.us</li>
                <li className='pt-2 flex flex-row-3 gap-6 cursor-pointer'>
                  <Link to='https://twitter.com/success4inc'>
                    <img src={Twitter} />
                  </Link>
                  <Link to='https://www.linkedin.com/company/success4-inc/'>
                    <img src={Linkedln} />
                  </Link>
                  <Link to='https://www.youtube.com/channel/UCrrmrj5mw0630yrnBks8Ylw'>
                    <img src={Youtube} />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='py-[2vh] absolute z-40 w-full '>
        <hr />
        <div className='flex justify-between md:px-[10%] '>
          <div>
            <p className='text-grayColor text-sm py-2 '>Copyright © 2024 Success4 Inc. All rights reserved</p>
          </div>
          <div className='flex gap-5 text-grayColor text-sm p-2 '>         
            <a href="/privacypolicy"  className="cursor-pointer" >Privacy Policy</a>            
            <a href="/termsservices"  className="cursor-pointer">Terms Of Service</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
