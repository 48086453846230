import React from 'react';

const TermsService = () => {
  return (
    <div className="mx-auto py-6 flex flex-col text-justify font-nunito-sans md:px-[25%] px-[2%]">
      <h1 className="text-6xl font-bold mb-4 text-center text-[#3E66F2]">Terms of Service</h1>
      <p className="text-sm text-gray-600">Effective Date: 01-01-2024</p>

      <section className="mt-8 text-lg">
        <p className="mt-2">Welcome to <span className='font-bold'>Success4</span> (referred to as "the Application"). By accessing or using the Application, you agree to comply with and be bound by the following terms and conditions ("Terms of Service"). Please read them carefully.</p>
      </section>

      <section className="mt-8 text-lg">
        <h2 className="text-3xl font-semibold text-[#3E66F2]">1. Acceptance of Terms</h2>
        <p className="mt-2">By accessing or using <span className='font-bold'>Success4</span>, you agree to these Terms of Service. If you do not agree to these terms, you must not use the Application.</p>
      </section>

      <section className="mt-8 text-lg">
        <h2 className="text-3xl font-semibold text-[#3E66F2]">2. Changes to Terms</h2>
        <p className="mt-2">We may modify these Terms of Service at any time. Any changes will be effective immediately upon posting the updated Terms of Service on the Application. Your continued use of the Application following the posting of changes signifies your acceptance of those changes.</p>
      </section>

      <section className="mt-8 text-lg">
        <h2 className="text-3xl font-semibold text-[#3E66F2]">3. User Responsibilities</h2>
        <p className="mt-2">You must provide accurate and current information when using the Application. You agree to use the Application only for lawful purposes and in a manner that does not violate the rights of others.</p>
      </section>

      <section className="mt-8 text-lg">
        <h2 className="text-3xl font-semibold text-[#3E66F2]">4. Access and Use of Services</h2>
        <p className="mt-2">You are granted a limited, non-exclusive, non-transferable license to use the Application for personal or business purposes. Unauthorized use of the Application is prohibited.</p>
      </section>

      <section className="mt-8 text-lg">
        <h2 className="text-3xl font-semibold text-[#3E66F2]">5. Data Usage and Privacy</h2>
        <p className="mt-2">By using the Application, you grant us the right to access and use certain data, including personal information, in accordance with our <a href="/privacypolicy" className="text-blue-800">Privacy Policy</a>.</p>
        <p className="mt-2"> <span className="text-xl font-semibold "> Gmail Data Access:</span> If you grant access to your Gmail account, the Application will retrieve and display email metadata such as subject, sender, and snippet. We do not store, modify, or delete your emails. Your data will only be used for the intended purpose of improving your user experience within the Application.</p>
      </section>

      <section className="mt-8 text-lg">
        <h2 className="text-3xl font-semibold text-[#3E66F2]">6. Third-Party Services</h2>
        <p className="mt-2">Our Application may integrate with third-party services, such as Google APIs, which are subject to their own terms and conditions. By using the Application, you agree to comply with all applicable third-party terms and policies.</p>
      </section>

      <section className="mt-8 text-lg">
        <h2 className="text-3xl font-semibold text-[#3E66F2]">7. Limitation of Liability</h2>
        <p className="mt-2"><span className='font-bold'>Success4</span> is provided "as is" without warranty of any kind. We do not guarantee that the Application will meet your requirements or that it will be uninterrupted or error-free. In no event shall <span className='font-bold'>Success4</span> be liable for any direct, indirect, incidental, or consequential damages arising from your use of or inability to use the Application.</p>
      </section>

      <section className="mt-8 text-lg">
        <h2 className="text-3xl font-semibold text-[#3E66F2]">8. Termination</h2>
        <p className="mt-2">We reserve the right to suspend or terminate your access to the Application at any time, for any reason, without notice.</p>
      </section>

      <section className="mt-8 text-lg">
        <h2 className="text-3xl font-semibold text-[#3E66F2]">9. Governing Law</h2>
        <p className="mt-2">These Terms of Service are governed by and construed in accordance with the laws of <span className='font-bold'>California, USA.</span> Any disputes arising from or related to these terms will be resolved in the courts of <span className='font-bold'>California.</span> </p>
      </section>

      <section className="mt-8 text-lg">
        <h2 className="text-3xl font-semibold text-[#3E66F2]">10. Contact Information</h2>
        <p className="mt-2">If you have any questions about these Terms of Service, please contact us at:</p>
        <p className="mt-2"><strong>Email:</strong> <a href="mailto:support@success4.us" className="text-blue-500">support@success4.us</a></p>
        <p className="mt-2"><strong>Phone:</strong> +1-415-938-6456</p>
        <p className="mt-2"><strong>Sales Email:</strong> <a href="mailto:sales@success4.us" className="text-blue-500">sales@success4.us</a></p>
        <p className="mt-2"><strong>Address:</strong> 1525 McCarthy Blvd, Milpitas, CA 95035</p>
      </section>
    </div>
  );
};

export default TermsService;
